import React from 'react'
// import { RichText } from 'prismic-reactjs'
// import GatsbyLink from '../GatsbyLink'
import { Link } from 'gatsby'

const gridClasses = {
  mobile: {
    cols: {
      1: "grid-cols-1",
      2: "grid-cols-2",
      3: "grid-cols-3",
      4: "grid-cols-4",
      5: "grid-cols-5",
      6: "grid-cols-6",
      7: "grid-cols-7",
      8: "grid-cols-8",
      9: "grid-cols-9",
      10: "grid-cols-10",
    },
    rows: {
      1: "grid-row-1",
      2: "grid-row-2",
      3: "grid-row-3",
      4: "grid-row-4",
      5: "grid-row-5",
      6: "grid-row-6",
      7: "grid-row-7",
      8: "grid-row-8",
      9: "grid-row-9",
      10: "grid-row-10",
    }
  },
  desktop: {
    cols: {
      1: "md:grid-cols-1",
      2: "md:grid-cols-2",
      3: "md:grid-cols-3",
      4: "md:grid-cols-4",
      5: "md:grid-cols-5",
      6: "md:grid-cols-6",
      7: "md:grid-cols-7",
      8: "md:grid-cols-8",
      9: "md:grid-cols-9",
      10: "md:grid-cols-10",
    },
    rows: {
      1: "md:grid-row-1",
      2: "md:grid-row-2",
      3: "md:grid-row-3",
      4: "md:grid-row-4",
      5: "md:grid-row-5",
      6: "md:grid-row-6",
      7: "md:grid-row-7",
      8: "md:grid-row-8",
      9: "md:grid-row-9",
      10: "md:grid-row-10",
    }
  }
}

const gapClasses = {
  // mobile: {
  //     1: "grid-cols-1",
  //     2: "grid-cols-2",
  //     3: "grid-cols-3",
  //     4: "grid-cols-4",
  //     5: "grid-cols-5",
  //     6: "grid-cols-6",
  //     7: "grid-cols-7",
  //     8: "grid-cols-8",
  //     9: "grid-cols-9",
  //     10: "grid-cols-10",
  //   },

  // desktop: {
  1: "gap-1",
  2: "gap-2",
  3: "gap-3",
  4: "gap-4",
  5: "gap-5",
  6: "gap-6",
  7: "gap-7",
  8: "gap-8",
  9: "gap-9",
  10: "gap-10",
  // }

}



const ImageGrid = ({ slice }) => {
  const gridDesktop = gridClasses.desktop[slice.primary.type][slice.primary.desktop]
  const gridMobile = gridClasses.mobile[slice.primary.type][slice.primary.mobile]

  const gap = gapClasses[slice.primary.gap]


  return (
    <div className={`my-12 py-4 grid ${gridMobile} ${gridDesktop} ${gap}`}>
      {slice.items.map((item, index) => <img
        loading="lazy"
        key={item.image ? item.image.url : index} //TODO
        className="pointer-events-none select-none"
        src={item.image ? item.image.url : ''}
        alt={item.image ? item.image.alt : ''}
      />)}
    </div>
  )
}

export default ImageGrid
