import React from 'react'
// import { RichText } from 'prismic-reactjs'
// import GatsbyLink from '../GatsbyLink'
import { Link } from 'gatsby'

const FullWidthImage = ({ slice }) => {
  const image = (
    <img
      loading="lazy"
      className="pointer-events-none select-none"
      src={slice.primary.image ? slice.primary.image.url : ''}
      alt={slice.primary.image && slice.primary.image.alt ? slice.primary.image.alt : 'Full Width Image'}
    />
  )

  if (slice.primary.link?.url) {
    return <Link to={slice.primary.link.url}>{image}</Link>
  }

  return image
}

export default FullWidthImage
