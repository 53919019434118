import React from 'react'
import { RichText as PrismicRichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'

const RichText = ({ slice }) => {
    const text_align = {
        left: "text-left",
        right: "text-right",
        center: "text-center",
        justify: "text-justify"
    }
    const align = text_align[slice.primary.text_align]

    const container = slice.primary.container_center ? "mx-auto" : ""

    return (
        <div className={`prose ${container} my-8 ${align}`}>
            <PrismicRichText render={slice.primary.content.raw || []} serializeHyperlink={GatsbyLink} />
        </div>
    )
}

export default RichText
