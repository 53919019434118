import React from 'react'
import {
  CTA,
  FullWidthImage,
  ImageGrid,
  TextImage,
  NavigationLink,
  NavigationLinkGroup,
  NavigationButton,
  RichText,
  EmbedVideo
} from './slices'

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    cta: CTA,
    full_width_image: FullWidthImage,
    image_grid: ImageGrid,
    text_image: TextImage,
    name: NavigationLink,
    test: NavigationLinkGroup,
    link_button: NavigationButton,
    rich_text: RichText,
    embed_video: EmbedVideo
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
}

export default SliceZone
