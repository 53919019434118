import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Sidebar from './Sidebar'

const Layout = ({ children, sidebar, activeDocMeta, metadata }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery($lang: String) {
      site {
        siteMetadata {
          title
          description
          url
        }
      }
      prismicLayout(lang: {eq: $lang}) {
        data {
          image {
            url
          }
        }
       }
    }`)

  const title = metadata.title || data.site.siteMetadata.title
  const description = metadata.description || data.site.siteMetadata.description

  return (
    <div className="antialiased font-sans text-lg text-gray-700 flex flex-wrap">
      <Helmet htmlAttributes={{
        lang: activeDocMeta.lang.slice(0, 2)
      }}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="title" content={title}></meta>
        <meta name="description" content={description} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {metadata.no_index && <meta name="robots" content="noindex" />}

        <meta property="og:type" content="website" />
        <meta property="og:url" content={data.site.siteMetadata.url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={data.prismicLayout.data.image.url} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={data.site.siteMetadata.url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={data.prismicLayout.data.image.url} />
      </Helmet>

      <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
        <Sidebar sidebar={sidebar} activeDocMeta={activeDocMeta} />
        <main className="flex-1 p-4 md:pl-14 md:pt-8 max-w-screen-2xl">{children}</main>
      </div>

    </div>
  )
}

export default Layout
