import React from 'react'
import { Link, graphql } from 'gatsby'
import LanguageSwitcher from './LanguageSwitcher'

import SocialLinks from "./SocialLinks"
import useToggle from '../utils/useToggle'
import SliceZone from './SliceZone'

import { MdMenu, MdClose } from "react-icons/md"

const Sidebar = ({ sidebar, activeDocMeta }) => {
  const { isOpen, onToggle } = useToggle(false)

  // console.log("sidebar", sidebar, activeDocMeta)
  const lang = activeDocMeta.lang?.slice(0, 2)

  return (
    // <div className="md:flex flex-col md:flex-row md:min-h-screen w-full ">
    <div className="p-4 md:p-6 flex flex-col md:w-[325px] md:sticky md:top-0 md:h-screen" >
      <div className="flex items-center justify-between">
        <Link to={!lang || lang === "en" ? `/` : `/${lang}`}>
          <img
            className="hidden md:block h-10 md:h-auto pointer-events-none select-none"
            src={sidebar.logo.url}
            alt={sidebar.logo.alt || "Álvaro Serrano Logo"}
          />
            <img
            className="md:hidden h-10 md:h-auto pointer-events-none select-none"
            src={sidebar.logo_movil.url}
            alt={sidebar.logo_movil.alt || "Álvaro Serrano Logo"}
          />
        </Link>
        <button aria-label="menu button" className="md:hidden text-2xl text-gray-600" onClick={onToggle}>
          {isOpen ? <MdClose /> : <MdMenu />}
        </button>
      </div>

      <nav className={`${isOpen ? "block" : "hidden"} md:block md:overflow-y-auto`}>

        <div className="text-base py-4 text-gray-500">
          {sidebar.short_description.text}
        </div>

        <SliceZone slices={sidebar.body} />

        <div className="flex md:flex-col items-center md:items-start justify-between pt-4">
          <SocialLinks socialLinks={sidebar.social_links} />
          <div className="md:mt-8">
            <LanguageSwitcher activeDocMeta={activeDocMeta} />
          </div>
        </div>

      </nav>

    </div>
  )
}

export const query = graphql`
fragment LayoutFragment on PrismicLayout {
        data {
          logo {
            url
            alt
          }
          logo_movil {
            url
            alt
          }
          short_description {
            text
          }
          social_links {
            name
            link {
              target
              url
            }
          }
          body {
            ... on PrismicLayoutBodyLinkButton {
              slice_type
              primary {
                link {
                  url
                  uid
                }
                text {
                  text
                }
              }
            }
            ... on PrismicLayoutBodyName {
              primary {
                margin_top
                link {
                  url
                  link_type
                }
                text {
                  text
                }
              }
              slice_type
            }
            ... on PrismicLayoutBodyTest {
              primary {
                margin_top
                text {
                  text
                }
              }
              slice_type
              items {
                link {
                  id
                  url
                }
                text {
                  text
                }
              }
            }
          }
        }
}
`

export default Sidebar
