import React from 'react'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Link } from 'gatsby'
import { CgExternal } from "react-icons/cg"

const NavigationLink = ({ slice }) => {
  if (slice.primary.link.link_type === "Web" || slice.primary.link.link_type === "Media")
    return <OutboundLink
      href={slice.primary.link.url}
      target="_blank"
      rel="noreferrer"
      className={`flex items-baseline py-3 text-base font-semibold text-gray-700 hover:text-primary focus:text-gray-900 ${slice.primary.margin_top ? "mt-4" : ""}`}
    >
      {slice.primary.text.text} <CgExternal className="ml-1"/>
    </OutboundLink>

  return (
    <Link
      to={slice.primary.link.url}
      className={`block py-3 text-base font-semibold text-gray-700 hover:text-primary focus:text-gray-900 ${slice.primary.margin_top ? "mt-4" : ""}`}
      activeClassName="text-primary"
    >
      {slice.primary.text.text}
    </Link>
  )
}

export default NavigationLink
