import React from 'react'
// import { RichText } from 'prismic-reactjs'
// import GatsbyLink from '../GatsbyLink'
import { Link } from 'gatsby'

const NavigationButton = ({ slice }) => (
  <Link
    to={slice.primary.link.url}
    className="min-w-165 text-base hover:bg-primary hover:text-white my-4 rounded-full inline-flex items-center justify-center px-8 py-3 border-solid border border-primary text-primary leading-6 transition duration-150 ease-in-out font-semibold"
  >
    {slice.primary.text.text}
  </Link>
)

export default NavigationButton
