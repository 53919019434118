import React from 'react'
// import { RichText } from 'prismic-reactjs'
// import GatsbyLink from '../GatsbyLink'
import { Link } from 'gatsby'

const CTA = ({ slice }) => {

  const mx_auto = slice.primary.container_center ? "mx-auto" : ""

  return (
    <div className={`flex flex-col items-center justify-center my-12 ${mx_auto} max-w-screen-sm`}>
      <h1 className="text-3xl md:text-4xl md:text-[2.5rem] font-bold mb-4 text-gray-800">
        {slice.primary.heading.text}
      </h1>
      <p className="text-center">{slice.primary.text.text}</p>
      <Link
        to={slice.primary.link_button.url}
        className="min-w-165 my-4 rounded-full inline-flex items-center justify-center px-8 py-4 font-semibold border border-transparent leading-6 text-white bg-primary hover:bg-secondary focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      >
        {slice.primary.text_button.text}
      </Link>
    </div>
  )
}

export default CTA
