import React from 'react'
import { Link } from 'gatsby'
import useSessionStorage from '../../utils/useSessionStorage'

import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md"

const NavigationLinkGroup = ({ slice }) => {
  const [isOpen, setIsOpen] = useSessionStorage(`sidebar_navigation_${(slice.primary.text.text || new Date().getTime().toString()).toLowerCase().replace(/ /g, "_")}_is_open`, false)

  return (<div>
    <div
      onClick={() => setIsOpen(open => !open)}
      className={`cursor-pointer flex items-center justify-between py-3 text-base font-semibold text-gray-700 hover:text-primary focus:text-gray-900 ${slice.primary.margin_top ? "mt-4" : ""}`}
    >
      {slice.primary.text.text} {isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
    </div>
    <div className={isOpen ? "" : "hidden"}>
      {slice.items.map(item => (<Link
        key={item.link.id}
        to={item.link.url}
        className="block py-3 ml-6 text-base text-gray-700 hover:text-primary focus:text-gray-900"
        activeClassName="text-primary"
      >
        {item.text.text}
      </Link>))}
    </div>
  </div>
  )
}

export default NavigationLinkGroup
