import React, { createElement } from "react"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
    FaBehance,
    FaArtstation
} from "react-icons/fa";

const icons = {
    facebook: FaFacebookF,
    twitter: FaTwitter,
    instagram: FaInstagram,
    linkedin: FaLinkedinIn,
    behance: FaBehance,
    artstation: FaArtstation
};

const SocialLinks = ({ socialLinks }) => {
    return socialLinks
        ? <div className="flex">
            {socialLinks.map((socialLink) => (
                <OutboundLink
                    key={socialLink.name}
                    href={socialLink.link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="pr-6 text-gray-600 hover:text-primary"
                >
                    {createElement(icons[socialLink.name], { size: 22 })}
                </OutboundLink>
            ))}
        </div>
        : null
}

export default SocialLinks