import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useInView } from 'react-intersection-observer'

const EmbedVideo = ({ slice }) => {
    const [ref, inView] = useInView({
        threshold: 0,
    })
    const [visible, setVisible] = useState()

    useEffect(() => {
      if(inView && !visible) setVisible(true)
    }, [inView])

    return (
        <div ref={ref} className="relative pt-[56.25%]" >
            {(inView || visible) && <ReactPlayer url={slice.primary.link.url}
                width="100%"
                height="100%"
                className="absolute top-0 left-0"
                controls />}
        </div>
    )
}

export default EmbedVideo
