import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'

const TextImage = ({ slice }) => {

  const order = slice.primary.order === "image-text" ? "flex-row-reverse" : ""

  return (
    <div
      className={`my-12 flex flex-wrap py-4 ${order}`}
    >
      <div
        className={`w-full md:w-1/2 flex justify-center flex-col px-4`}
      >
        <RichText render={slice.primary.text.raw || []} serializeHyperlink={GatsbyLink} />
      </div>
      <div
        className="w-full md:w-1/2 px-4"
      >
        <img
          loading="lazy"
          className="pointer-events-none select-none"
          src={slice.primary.image ? slice.primary.image.url : ''}
          alt={slice.primary.image ? slice.primary.image.alt : ''}
        />
      </div>

    </div>
  )
}

export default TextImage
