import React from 'react'
import { Link } from 'gatsby'
import linkResolver from '../utils/linkResolver'

const LanguageSwitcher = ({ activeDocMeta }) => {
  const currentLang = activeDocMeta.lang

  const currentLangOption = (
    <div className="font-bold text-gray-400 mr-3 inline select-none">
      {currentLang.slice(0, 2).toUpperCase()}
    </div>
  )

  const alternateLangOptions = activeDocMeta.alternateLanguages.map((altLang, index) => (
    <Link
      key={`alt-lang-${index}`}
      to={linkResolver(altLang)}
      className="text-sm text-gray-400"
    >
      {altLang.lang.slice(0, 2).toUpperCase()}
    </Link>
  ))

  return (
    <div>
      {currentLangOption}
      {alternateLangOptions}
    </div>
  )
}

export default LanguageSwitcher
